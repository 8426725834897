<template>
    <el-container class="container">
        <div class="nei_box">
            <div class="box_one">
                <div class="title_flex">
                    <div class="line"></div>
                    <div class="text">职位发布</div>
                    <div class="line"></div>
                </div>
                <div class="text_box">
                    <span class="num_yan">1</span><span class="num_text">职位基本信息</span>
                    <div class="text_box_yan">职位发布成功后，招聘类型、职位名称、职位类型、工作城市，将无法修改</div>
                </div>
                <div class="title">
                    <div class="form_box">
                        <el-form :label-position="labelPosition" label-width="85px" :model="formLabelAlign">
                            <el-form-item label="企业名称">
                                <el-input v-model="formLabelAlign.company_name" placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="职位名称">
                                <el-input v-model="formLabelAlign.job_name" placeholder="请输入"></el-input>
                            </el-form-item>

                            <el-form-item label="职位描述">
                                <el-input type="textarea" :rows="5"  placeholder="多行输入" v-model="formLabelAlign.job_describe"></el-input>
                            </el-form-item>
                            <el-form-item label="行业领域">
                                <el-select v-model="formLabelAlign.territory"  placeholder="选择">
                                    <el-option
                                            v-for="item in lingyu"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="企业性质">
                                <el-select v-model="formLabelAlign.nature"  placeholder="选择">
                                    <el-option
                                            v-for="item in qiye"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

                <div class="text_box_two">
                    <span class="num_yan">2</span><span class="num_text">职位要求</span>
                    <div class="text_box_yan">职位发布成功后，招聘类型、职位名称、职位类型、工作城市，将无法修改</div>

                </div>
                <div class="title">
                    <div class="form_box">
                        <el-form ref="formLabelAlign" :label-position="labelPosition" :model="formLabelAlign" label-width="85px">
                            <el-form-item label="工作经验">
                                <el-select v-model="formLabelAlign.exp"  placeholder="请选择经验">
                                    <el-option
                                            v-for="item in jing"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                               </el-form-item>

                                <el-form-item label="招聘人数">
                                <el-select
                                        v-model="formLabelAlign.p_number"
                                        placeholder="请选择人数">
                                    <el-option
                                            v-for="item in renss"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="学历要求">
                                <el-select v-model="formLabelAlign.education"  placeholder="选择学历">
                                    <el-option
                                            v-for="item in xueli"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                                <el-form-item label="学位要求">
                                <el-select
                                        v-model="formLabelAlign.degree"
                                        collapse-tags
                                        placeholder="选择学位">
                                    <el-option
                                            v-for="item in xuewei"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="薪资范围">
                                <el-input   @mousewheel.native.prevent type="number" style="width:95%"  v-model="formLabelAlign.salary"   placeholder="请输入"></el-input>
                                <span style="margin-left:5px;font-size: 20px;">k</span>
                            </el-form-item>
                            <el-form-item label="职位关键词">
                                <el-input v-model="formLabelAlign.job_keyword" placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="岗位要求">
                                <el-input v-model="formLabelAlign.require " placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="岗位职责">
                                <el-input v-model="formLabelAlign.duty" placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="上传图片" class="header" prop="img">
                                <el-upload
                                        :on-change="fns"
                                        action=""
                                        list-type="picture-card"
                                        :on-preview="handlePictureCardPreviewG"
                                        :on-remove="handleRemoveG"
                                        :auto-upload="false"
                                        accept="image/*"
                                        :class="{ hide: hideUploadG }"
                                        class="avatar-uploader"
                                >
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="" />
                                </el-dialog>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">发布</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>

        </div>
    </el-container>
</template>

<script>
    import { handleCompressImg } from "../../../api";
    import { recruit_add,rens,experience,education,degree,industry,prise} from "../../common/js/api";
    export default {
        name: "Sucessresult",
        data() {
            return {
                dialogVisible: false,
                dialogImageUrl: "",
                hideUploadG: false,
                value1:"",
                value2:"",
                labelPosition: 'left',
                formLabelAlign: {
                    member_id:JSON.parse(localStorage.getItem("user")).id,
                    company_name:"",
                    job_name:"",
                    job_describe:"",
                    territory:"",
                    nature:"",
                    exp	:"",
                    p_number:"",
                    education:"",
                    degree:"",
                    salary:"",
                    job_keyword:"",
                    require:"",
                    duty:"",
                    img:""
                },
                renss:[], //人数
                jing:[],
                xuewei:[], //
                xueli:[],
                qiye:[],
                lingyu:[],
            };
        },
        created(){
			if(!localStorage.getItem("user")){
					  this.$router.push({
					    path: "/login",
					  });
			}
            this.ren_shu()
            this.jing_yan()
            this.xue_li()
            this.xue_wei()
            this.ling_yus()
            this.qi_yes()
        },
        methods: {
            fns(file, fileList) {
                this.hideUploadG = fileList.length >= 1;
                handleCompressImg(file.raw).then((result) => {
                    this.UploadImgs(result, 1);
                });
            },
            //将图片地址上传
            async UploadImgs(file) {
                let formData = new FormData();
                formData.append("file", file);
                await this.$axios
                    .post("http://hout.kehui.cloud/api/demand/uploadImg", formData, {
                        headers: { "Content-Type": '"multipart/form-data"' },
                    })
                    .then((res) => {

                        this.formLabelAlign.img = res.data.data;
                    });
            },
            handleRemoveG(file, fileList) {
                this.hideUploadG = fileList.length >= 1;
            },
            handlePictureCardPreviewG(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            onSubmit() {
                recruit_add({
                    data:this.formLabelAlign
                }).then((res) => {
                    if(res.code==200){
                        this.$message({
                            message:res.msg,
                            type: 'success'
                        });
                        this.$router.push({
                            path:"/humancloud"
                        })
                    }else if(res.code==202){
                        this.$message({
                            message:res.msg,
                            type: 'success'
                        });
                    }
                    else {
                        this.$message({
                            message:res.msg,
                            type: 'success'
                        });
                    }
                }).catch((err) => {
                });
            },
            qi_yes(){
                prise({}) .then((res) => {
                    this.qiye=res.data
                }).catch((err) => {
                });
            },
            ling_yus(){
                industry({}) .then((res) => {
                    for(var i=0;i<res.data.length;i++){
                        Object.assign(res.data[i],{isSelected: false});
                    }
                    this.lingyu=res.data
                }).catch((err) => {
                });
            },
            xue_wei(){
                degree({}) .then((res) => {
                    for(var i=0;i<res.data.length;i++){
                        Object.assign(res.data[i],{isSelected: false});
                    }
                    this.xuewei=res.data
                }).catch((err) => {
                });
            },
            xue_li(){
                education({}).then((res) => {
                    for(var i=0;i<res.data.length;i++){
                        Object.assign(res.data[i],{isSelected: false});
                    }
                    this.xueli=res.data
                }).catch((err) => {
                });
            },
            jing_yan(){
                experience({}).then((res) => {
                    this.jing=res.data
                    console.log(res.data)
                }).catch((err) => {
                });
            },
            ren_shu(){
                rens({}).then((res) => {
                    this.renss=res.data
                }).catch((err) => {
                });
            }

        }
    }
</script>

<style scoped lang="less">

    /deep/input::-webkit-outer-spin-button,
    /deep/input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    /deep/input[type='number'] {
        -moz-appearance: textfield;
    }
        /deep/.avatar-uploader{
      width: 110px;

    }
     /deep/.hide .el-upload--picture-card {
        display: none;
    }
    /deep/.el-button{
        margin-top: 20px;
        margin-left: -50px;
		font-size: 18px;
    }
    /deep/.el-select{
        width: 100%;
    }
    /deep/.el-textarea__inner{
   resize: none;
}
    .container{
        background-color: #F8F9FA;
        width: 100%;
        height: auto;
        overflow: hidden;
		margin-top: 135px;
        .nei_box{
            overflow: hidden;
            margin: 30px auto;
            width: 1200px;
            height: 1670px;
            line-height: 20px;
            border-radius: 5px;
            background-color: rgba(56, 146, 235, 100);
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 100);
            .box_one{
                width: 1130px;
                height:1580px;
                background-color: #fff;
                line-height: 20px;
                margin: 50px auto;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 100);
                .text_box{
                    text-align: left;
                    margin: 0px 0px 30px 230px;
                    .text_box_yan{
                        margin-top: 30px;
                        margin-left: 25px;

                    }
                    .num_yan{
                        padding-right: 5px;
                        vertical-align: middle;
                        color: rgba(56, 146, 235, 100);
                        font-size: 36px;
                        text-align: left;
                    }
                    .num_text{
                        vertical-align: middle;
                        color: rgba(56, 146, 235, 100);
                        font-size: 18px;
                    }
                }
                .text_box_two{
                    text-align: left;
                    margin: 366px 0px 30px 230px;
                    .text_box_yan{
                        margin-top: 30px;
                        margin-left: 25px;

                    }
                    .num_yan{
                        padding-right: 5px;
                        vertical-align: middle;
                        color: rgba(56, 146, 235, 100);
                        font-size: 36px;
                        text-align: left;
                    }
                    .num_text{
                        vertical-align: middle;
                        color: rgba(56, 146, 235, 100);
                        font-size: 18px;

                    }
                }
                .title_flex{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .text{
                        font-size: 28px;
                        color:#3892EB;
                        font-weight: 600;

                    }
                    .line{
                        margin:100px;
                        width: 60px;
                        height: 1px;
                        background-color: #3892EB ;
                    }
                }
                .form_box{
                    margin: 0 auto;
                    width: 613px;
                    height: 30px;
                }

            }
        }
    }
	@media screen and (max-width: 1200px) {
		.container{
			margin-top: 15rem;
		}
		.container .nei_box{
			width: 98%;
			height: auto;
		}
		.w{
			width: 98%;
		}
		.container .nei_box .box_one{
			width: 95%;
			height: 1700px;
		}
		.container .nei_box .box_one .text_box{
			margin-left: 0;
		}
		.container .nei_box .box_one .text_box_two{
			margin-left: 0;
		}
		.container .nei_box .box_one .form_box{
			width: 95%;
		}
		.container .nei_box .box_one .text_box .num_yan{
			font-size: 2rem;
		}
		.container .nei_box .box_one .text_box .num_text{
			font-size: 2rem;
		}
		.container .nei_box .box_one .text_box .text_box_yan{
			font-size: 1.4rem;
			line-height: 2rem;
		}
		/deep/.el-form-item__label{
			font-size: 1.4rem;
			width: 8rem !important;
		}
		/deep/.el-form-item__content{
			margin-left: 8rem !important;
			font-size: 1.4rem;
			margin: 0;
		}
		/deep/.el-input{
			font-size: 1.4rem;
		}
		.container .nei_box .box_one .text_box_two .num_yan{
			font-size: 2rem;
		}
		.container .nei_box .box_one .text_box_two .num_text{
			font-size: 2rem;
		}
		.container .nei_box .box_one .title_flex .text{
			font-size: 2.2rem
		}
		
		/deep/.el-button{
			font-size: 1.6rem;
			margin: 0 !important;
		}
	}
</style>